import logo from './logo.svg';
import './App.css';
import Form from './components/Form';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

var link_css = "text-blue-300 underline hover:text-blue-100";

// Automatically require all images from the /public/images folder
function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('./images', false, /\.(HEIC|png|jpe?g|svg)$/)).map(image => ({
  original: image,
  thumbnail: image,
}));

function App() {

  return (
    <div className='text-white bg-black items-center h-screen text-center pt-10 sm:pt-32'>
      Hi! Currently, this website is under construction. <br />
       <br />
      Meanwhile, check out my job profile on <a className={link_css} href="https://www.linkedin.com/in/christoph-saffer/" target="_blank">LinkedIn</a>, thoughts
      on <a className={link_css} href="https://x.com/ChristophSaffer" target="_blank">X (Twitter)</a> or blog articles
      on <a className={link_css} href="https://medium.com/@ChristophSaffer" target="_blank">Medium</a>.<br /><br />
      
        
        {/*<div className='text-black bg-white sm:m-4 sm:p-8 pt-8 pb-8 border rounded-2xl max-w-screen-sm'>
          Leave me your email address if you'd like to hear from me more regularly:<br /><br />
          <Form />
        </div>*/}

        <div className='mt-1'>
          More about some of my fun projects:
          <div className='grid place-items-center'>
          <div>
          <ul className='list-disc text-left pl-24'>
            <li>
              <a className={link_css} href="https://christophsaffer.com/apps/blinds/" target="_blank">Behavior of blinds</a>
            </li>
            <a>
              <li>
                <a className={link_css} href="https://twitter.com/alligatoah_snip" target="_blank">Twitter-Fanbot</a>
              </li>
            </a>
          </ul>
          </div>
          </div>
        </div>

        <div className='mt-4 bg-black w-full'>
          And here, some images from my PhD and master studies: 
          <div className='mt-4'>
          <ImageGallery items={images} />
          </div>
        </div>

    </div>
  );
}

export default App;
